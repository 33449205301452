<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>会员列表</el-breadcrumb-item>
            <el-breadcrumb-item>修改会员等级</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">
        <el-form-item label="会员编号">
          {{ form.userAccount }}
        </el-form-item>

        <el-form-item label="会员等级">
          <el-select v-model="form2.gradeno" placeholder="请选择">
            <el-option v-for="item in vipdata" :key="item.gradeno" :label="item.name" :value="item.gradeno"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getSelectRequest } from "@/api/wx_MemberUserGrade";
import { getdatilgrade, updateusergradeByID } from "@/api/wxuser";
import { getdicdtabykeyRequest } from "@/api/sysdicdata";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Useredit",
  data() {
    return {
      form: { id: 0 },
      form2: {},
      selectList: []
    };
  },
  created() {
    this.form.id = this.$route.params.id;
    this.getSelect();
    this.getdatil();
  },
  methods: {
    back() {
      this.$router.back();
    },
    getdatil() {
      getdatilgrade({
        Id: this.form.id
      }).then(res => {
        this.form = res.data.user;
        this.form2 = res.data.usergrade;
      });
    },
    getSelect() {
      getSelectRequest().then(res => {
        this.vipdata = res.data;
      });
    },
    geteducationdata(groupname) {
      getdicdtabykeyRequest({ groupname: groupname }).then(res => {
        console.log(res);
        if (groupname == "vipgradekey") {
          res.data.shift();
          res.data = res.data.map(item => {
            return {
              value: parseInt(item.value),
              name: item.name
            };
          });

          this.vipdata = res.data;
        }
      });
    },
    save() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        updateusergradeByID({ id: this.form.id, gradeno: this.form2.gradeno }).then(res => {
          if (res.code == 1) {
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  },
  validations: {
    form: {}
  }
};
</script>

<style lang="less" scoped>

</style>
